const handleClickBehavior = (event) => {
  const $clickedElement = event.currentTarget
  const idToScroll = $clickedElement.getAttribute('data-scroll-to')
  const $elementToScroll = document.querySelector<HTMLDivElement>(`#${idToScroll}`)
  if ($elementToScroll) {
    $elementToScroll.scrollIntoView()
  }
}
const bindButtons = () => {
  const $buttons = document.querySelectorAll<HTMLButtonElement>('[data-scroll-to]')
  $buttons.forEach(($singleButton) => {
    $singleButton.disabled = false
    $singleButton.addEventListener('click', handleClickBehavior)
  })
}

const initDragButtons = () => {
  bindButtons()
}

export default initDragButtons
