import { t9n } from 't9n/t9n'

let isTableExpanded = false
const $tableSafeRate = document.querySelector<HTMLDivElement>('[data-transations-table]')
const $buttonSafeRate = document.querySelector<HTMLButtonElement>('[data-show-table]')
const $tableRowHeight = $tableSafeRate?.querySelector<HTMLTableRowElement>('tbody tr').offsetHeight
const $tableHeaderHeight = $tableSafeRate?.querySelector<HTMLTableRowElement>('thead tr').offsetHeight

const setTableHeight = (): void => {
  if ($tableSafeRate && $tableRowHeight && $tableHeaderHeight) {
    $tableSafeRate.style.height = isTableExpanded ? 'auto' : `${$tableRowHeight * 5 + $tableHeaderHeight}px`
  }
}

const changeButton = (): void => {
  $buttonSafeRate.textContent = isTableExpanded ? `${t9n('howItWorks.hideButton')}` : `${t9n('howItWorks.showButton')}`
  $buttonSafeRate.classList.toggle('is-open')
}

const handleExpandButton = (): void => {
  $buttonSafeRate?.addEventListener('click', (): void => {
    isTableExpanded = !isTableExpanded
    setTableHeight()
    changeButton()
  })
}

const initExpandTableButton = (): void => {
  handleExpandButton()
  setTableHeight()
}

export default initExpandTableButton
