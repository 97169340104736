// Animation for own rate demo.
const box = document.querySelector<HTMLDivElement>('[data-animation-pricebox]')
const item = document.querySelector<HTMLDivElement>('[data-animation-container]')
const stack = document.querySelector<HTMLDivElement>('[data-stash-open]')
const stackItems = document.querySelectorAll<HTMLDivElement>('[data-stash-item]')

function ownRateDemoAnimation() {
  if (box) {
    item.classList.remove('is-faded')

    stackItems.forEach((stackItem) => {
      stackItem.classList.remove('is-closer')
    })

    setTimeout(() => {
      item.classList.add('is-in-center')
    }, 1000)
    setTimeout(() => {
      item.classList.add('is-isometric')
      box.classList.remove('is-not-in-stack')
    }, 1800)
    setTimeout(() => {
      stack.classList.add('is-open')
    }, 2200)
    setTimeout(() => {
      item.classList.add('is-stacked')
    }, 2600)

    setTimeout(() => {
      item.classList.add('is-faded')
      stack.classList.remove('is-open')
      stackItems.forEach((stackItem) => {
        stackItem.classList.add('is-closer')
      })
    }, 5500)

    setTimeout(() => {
      item.classList.remove('is-stacked')
      item.classList.remove('is-isometric')
      item.classList.remove('is-in-center')
      box.classList.add('is-not-in-stack')
    }, 6000)
  }
}

const initAnimation = () => {
  ownRateDemoAnimation()
  setInterval(() => {
    ownRateDemoAnimation()
  }, 8000)
}

export default initAnimation
