import switchInfo from './components/banks-text-switch'
import initDragButtons from './components/payment-method-drag'
import initAnimation from './components/animations'
import { infoService } from './components/info-tip-service'
import initExpandTableButton from './components/expand-table'

switchInfo()
initDragButtons()
initAnimation()
infoService()
initExpandTableButton()
