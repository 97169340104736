export const infoService = () => {
  const $infoHolders = document.querySelectorAll<HTMLSpanElement>('.options-table__sign-holder')
  if ($infoHolders) {
    $infoHolders.forEach((holder) => {
      holder.addEventListener('mouseover', () => {
        holder.querySelector<HTMLSpanElement>('.options-table__answer-info').hidden = false
      })
    })

    $infoHolders.forEach((holder) => {
      holder.addEventListener('mouseleave', () => {
        holder.querySelector<HTMLSpanElement>('.options-table__answer-info').hidden = true
      })
    })
  }
}
