const $switchButtons = document.querySelectorAll<HTMLButtonElement>('[data-button]')
const $dataContentType = document.querySelector<HTMLDivElement>('[data-content-type]')

const switchInfo = (): void => {
  if ($switchButtons && $dataContentType)
    $switchButtons.forEach((button) => {
      const atribut = button.getAttribute('data-button')
      button.addEventListener('click', (): void => {
        const attributeToHide = document
          .querySelector<HTMLDivElement>('[data-content-type]')
          .getAttribute('data-content-type')
        const $elementToHide = document.querySelectorAll<HTMLDivElement>(`.is-${attributeToHide}`)
        $elementToHide.forEach((el) => {
          el.hidden = true
        })
        $dataContentType.setAttribute('data-content-type', `${atribut}-than-5`)
        const attributeToShow = document
          .querySelector<HTMLDivElement>('[data-content-type]')
          .getAttribute('data-content-type')
        const $elToShow = document.querySelectorAll<HTMLDivElement>(`.is-${attributeToShow}`)
        $elToShow.forEach((el) => {
          el.hidden = false
        })
      })
    })
}

export default switchInfo
